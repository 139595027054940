import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]
  
  connect() {
    this._bindEvents()
  }

  _bindEvents() {
    window.addEventListener("notification:show", (event) => {
      this._createNotification(event.detail)
    })
  }

  _createNotification({ message, type = "info", duration = 3000, element = null }) {
    const notification = element || document.createElement("div")
    
    // Apply base styles - removed 'fixed' since container is fixed
    const baseClasses = "px-4 py-2 rounded-md text-white opacity-0 transition-opacity duration-300 min-w-[200px]"
    const typeClasses = {
      success: "bg-green-500",
      error: "bg-red-500",
      info: "bg-blue-500",
      warning: "bg-yellow-500"
    }
    
    notification.className = `${baseClasses} ${typeClasses[type] || typeClasses.info}`
    
    if (!element) {
      notification.textContent = message
    }
    
    // Add to container
    this.containerTarget.appendChild(notification)
    
    // Animate in
    setTimeout(() => notification.classList.add("opacity-100"), 10)
    
    // Remove after duration
    setTimeout(() => {
      notification.classList.remove("opacity-100")
      setTimeout(() => notification.remove(), 300)
    }, duration)
  }
} 
