import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["password", "confirmation", "strengthMeter", "rules", "ruleLength", "ruleLetters", "ruleNumbers", "ruleSpecial", "confirmationStatus", "submitButton"]

  static values = {
    minLength: { type: Number, default: 12 },
    requireLetters: { type: Boolean, default: true },
    requireNumbers: { type: Boolean, default: true },
    requireSpecial: { type: Boolean, default: true },
    requireConfirmation: { type: Boolean, default: true },
    successClass: { type: String, default: "text-green-500" },
    errorClass: { type: String, default: "text-red-600" },
    successBorderClass: { type: String, default: "border-green-500" },
    errorBorderClass: { type: String, default: "border-red-500" },
    successIndicator: { type: String, default: "✓" },
    errorIndicator: { type: String, default: "•" }
  }

  connect() {
    this._passwordRules.forEach(({ target, required = true }) => {
      if (required) target.classList.add(this.errorClassValue)
      else target.classList.add('hidden')
    })

    this._setupRules()
    this.validatePassword()
    this._updateSubmitButton()
  }

  submit(event) {
    event.preventDefault()
    // If all validations pass, submit the form
    if (!this._isPasswordValid()) return false
    this.element.closest('form').submit()
  }

  validatePassword() {
    const password = this.passwordTarget.value
    this._updateStrengthMeter(password)
    this._validateRules(password)
    if (this.requireConfirmationValue) this._validateConfirmation()
    this._updateSubmitButton()
  }

  // private

  get _passwordRules() {
    return [
      {
        target: this.ruleLengthTarget,
        check: (password) => password.length >= this.minLengthValue,
        isValid: (password) => password.length >= this.minLengthValue,
        required: true
      },
      {
        target: this.ruleLettersTarget,
        check: (password) => /[a-zA-Z]/.test(password),
        isValid: (password) => /[a-zA-Z]/.test(password),
        required: this.requireLettersValue
      },
      {
        target: this.ruleNumbersTarget,
        check: (password) => /\d/.test(password),
        isValid: (password) => /\d/.test(password),
        required: this.requireNumbersValue
      },
      {
        target: this.ruleSpecialTarget,
        check: (password) => /[^A-Za-z0-9]/.test(password),
        isValid: (password) => /[^A-Za-z0-9]/.test(password),
        required: this.requireSpecialValue
      }
    ]
  }

  _setupRules() {
    if (!this.requireConfirmationValue) {
      this.confirmationTarget.closest('.w-1/2').classList.add('hidden')
      this.passwordTarget.closest('.w-1/2').classList.remove('w-1/2')
    }
  }

  _validateConfirmation() {
    const password = this.passwordTarget.value
    const confirmation = this.confirmationTarget.value

    if (!confirmation) {
      this.confirmationTarget.classList.remove(this.errorBorderClassValue, this.successBorderClassValue)
      this.confirmationStatusTarget.textContent = ""
      return
    }

    const isMatch = password === confirmation
    this._updateConfirmationStatus(isMatch)
  }

  _updateConfirmationStatus(isMatch) {
    const { classList } = this.confirmationTarget
    const statusClassList = this.confirmationStatusTarget.classList

    classList.remove(isMatch ? this.errorBorderClassValue : this.successBorderClassValue)
    classList.add(isMatch ? this.successBorderClassValue : this.errorBorderClassValue)

    statusClassList.remove(isMatch ? this.errorClassValue : this.successClassValue)
    statusClassList.add(isMatch ? this.successClassValue : this.errorClassValue)

    this.confirmationStatusTarget.textContent = isMatch
      ? `${this.successIndicatorValue} ${I18n.password_strength.match}`
      : `${this.errorIndicatorValue} ${I18n.password_strength.no_match}`
  }

  _updateStrengthMeter(password) {
    const strength = (this._passwordRules.filter(rule => rule.check(password)).length / this._passwordRules.length) * 100
    this.strengthMeterTarget.style.width = `${strength}%`

    const strengthClasses = {
      25: "bg-red-500",
      50: "bg-orange-500",
      75: "bg-yellow-500",
      100: "bg-green-500"
    }

    const colorClass = Object.entries(strengthClasses)
      .find(([threshold]) => strength <= Number(threshold))?.[1] || strengthClasses[100]

    this.strengthMeterTarget.classList = `h-full rounded-full ${colorClass} transition-all`
  }

  _validateRules(password) {
    let allValid = true

    this._passwordRules.forEach(({ target, isValid, required = true }) => {
      if (!required) return

      const valid = isValid(password)
      if (!valid) allValid = false

      target.classList.replace(
        valid ? this.errorClassValue : this.successClassValue,
        valid ? this.successClassValue : this.errorClassValue
      )
      target.querySelector('.indicator').textContent = valid
        ? this.successIndicatorValue
        : this.errorIndicatorValue
    })

    return allValid
  }

  _isPasswordValid() {
    const password = this.passwordTarget.value
    const rulesValid = this._validateRules(password)
    const confirmationValid = !this.requireConfirmationValue ||
                            password === this.confirmationTarget.value

    return rulesValid && confirmationValid
  }

  _updateSubmitButton() {
    if (this.hasSubmitButtonTarget) {
      const isValid = this._isPasswordValid()

      // Update button state
      this.submitButtonTarget.disabled = !isValid
      this.submitButtonTarget.setAttribute('aria-disabled', !isValid)

      // Update button appearance
      if (isValid) {
        this.submitButtonTarget.classList.remove('opacity-50', 'cursor-not-allowed', 'pointer-events-none')
      } else {
        this.submitButtonTarget.classList.add('opacity-50', 'cursor-not-allowed', 'pointer-events-none')
      }
    }
  }
}
