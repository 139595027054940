import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "question", "spinnerContainer", "container", "submitButton", "newQuestionPrompt" ]
  static values = { url: String }

  connect(){
    // Disable submit button by default
    this._disableSubmit();
    
    let timeout;
    this.questionTarget.addEventListener("input", () => {
      // Reset state immediately when user starts typing
      this._disableSubmit();
      this.newQuestionPromptTarget.classList.add("hidden");
      
      clearTimeout(timeout);
      
      timeout = setTimeout(() => {
        const query = this.questionTarget.value;
        
        if (query.length > 3) {
          this.spinnerContainerTarget.classList.remove("hidden");
          this.containerTarget.classList.add("hidden");

          const headers = this._getRequestHeaders();
          const body = { question: query };

          fetch(this.urlValue, {
            method: "POST", 
            headers: headers,
            body: JSON.stringify(body)
          })
          .then(response => response.text())
          .then(html => {
            Turbo.renderStreamMessage(html);
            this._handleSearchResults();
          })
        }
      }, 400); // Wait after user stops typing
    });
  }

  disconnect(){
    this.questionTarget.removeEventListener("input", () => {
      console.log("removed event listener")
    });
  }

  // private

  _getRequestHeaders(){
    let csrfToken;

    if (document.querySelector("meta[name='csrf-token']")) {
      csrfToken = document.querySelector("meta[name='csrf-token']").content
    }

    return {
      Accept: "text/vnd.turbo-stream.html",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    };
  }

  // Wait a tiny bit for Turbo to finish rendering
  _handleSearchResults() {
    requestAnimationFrame(() => {
      this.containerTarget.classList.remove("hidden");
      this.spinnerContainerTarget.classList.add("hidden");
      
      // Check if there are any similar questions
      const hasSimilarQuestions = this.containerTarget.querySelector("ul li");
      
      if (hasSimilarQuestions) {
        // Delay showing the prompt
        setTimeout(() => {
          this.newQuestionPromptTarget.classList.remove("hidden");
          this._enableSubmit();
        }, 2000);
      } else {
        // If no results, enable button immediately and ensure prompt is hidden
        this.newQuestionPromptTarget.classList.add("hidden");
        this._enableSubmit();
      }
    });
  }

  _disableSubmit() {
    this.submitButtonTarget.disabled = true;
  }

  _enableSubmit() {
    this.submitButtonTarget.disabled = false;
  }
}
